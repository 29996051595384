module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kovoart","short_name":"Kovoart","start_url":"/","background_color":"#cda45e","theme_color":"#cda45e","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1ebca501c6e980a7ae60960a2747815f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Domů","crumbLabelUpdates":[{"pathname":"/sluzby","crumbLabel":"Nabízené služby"},{"pathname":"/reference","crumbLabel":"Naše reference"},{"pathname":"/laser","crumbLabel":"Laserové pracoviště"},{"pathname":"/kovarna","crumbLabel":"Kovárna Kovoart"},{"pathname":"/kontakt","crumbLabel":"Kontakt"},{"pathname":"/kalendar","crumbLabel":"Kalendář akcí"},{"pathname":"/projekteu","crumbLabel":"Projekty EU"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
