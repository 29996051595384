// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kalendar-js": () => import("./../../../src/pages/kalendar.js" /* webpackChunkName: "component---src-pages-kalendar-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-laser-js": () => import("./../../../src/pages/laser.js" /* webpackChunkName: "component---src-pages-laser-js" */),
  "component---src-pages-projekteu-js": () => import("./../../../src/pages/projekteu.js" /* webpackChunkName: "component---src-pages-projekteu-js" */),
  "component---src-pages-reference-js": () => import("./../../../src/pages/reference.js" /* webpackChunkName: "component---src-pages-reference-js" */),
  "component---src-pages-sluzby-js": () => import("./../../../src/pages/sluzby.js" /* webpackChunkName: "component---src-pages-sluzby-js" */),
  "component---src-templates-project-detail-js": () => import("./../../../src/templates/projectDetail.js" /* webpackChunkName: "component---src-templates-project-detail-js" */),
  "component---src-templates-service-page-js": () => import("./../../../src/templates/servicePage.js" /* webpackChunkName: "component---src-templates-service-page-js" */)
}

